import { createStore } from 'vuex'
import user from './modules/user'
import app from './modules/app'
import baseData from './modules/baseData'
import business from './modules/business'
//import getters from '@/store/getters'
export default createStore({
  // state: {
  // },
  // mutations: {
  // },
  // actions: {
  // },
  getters: {
    token: (state: any) => state.user.token,
    userInfo: (state) => state.user.userInfo,
    sideBarOpened:(state)=>state.app.sideBarOpened,
    language:(state)=>state.app.language,
    theme:(state)=>state.app.theme,
    tags:(state)=>state.app.tagsViewList,
    provences:(state)=>state.user.provences,   
    styleData:(state)=>state.user.styleData,
    categoryData:(state)=>state.user.categoryData,
    fabricCategoryData:(state)=>state.user.fabricCategoryData,
    decorationCategoryData:(state)=>state.user.decorationCategoryData,
    accessorieCategoryData:(state)=>state.user.accessorieCategoryData,
    sizeData:(state)=>state.user.sizeData,   
    settings :(state)=>state.user.settings,  
    onmessage:(state)=>state.app.onmessage,
    onsysmessage:(state)=>state.app.onsysmessage,
    socket:(state)=>state.app.socket, 
    functions:(state)=>state.user.allFunctions, 
    unitsData:(state)=>state.user.unitsData,
    sysFunctions:(state)=>state.user.sysFunctions, 
    printData:(state)=>state.user.printData,
    logisticsProviders:(state)=>state.user.logisticsProviders,
    appids:(state)=>{
      return {
        platform:state.user.platform,
        fabric:state.user.fabric,
        branch:state.user.branch,
        appId:state.user.appId,
        getAppletType:state.user.getAppletType
      }
    },
    hasUserInfo: (state: any) => {
      return JSON.stringify(state.user.userInfo) !== '{}'
    }
  },
  modules: {
    user,
    app,
    baseData,
    business
  }
})
