import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/images/logo-short.png'


const _withScopeId = n => (_pushScopeId("data-v-2496c8e4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "wraper" }
const _hoisted_2 = { class: "logo" }
const _hoisted_3 = ["src"]
const _hoisted_4 = {
  key: 0,
  class: "short"
}
const _hoisted_5 = {
  key: 1,
  class: "long"
}
const _hoisted_6 = {
  key: 2,
  class: "long1"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_menu_bar = _resolveComponent("menu-bar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.orgUrl)
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            class: _normalizeClass([_ctx.sideBarOpened?'':'collapse']),
            src: _ctx.orgUrl,
            alt: "",
            onerror: "this.src='/images/logo-short.png'"
          }, null, 10, _hoisted_3))
        : (_openBlock(), _createElementBlock("img", {
            key: 1,
            class: _normalizeClass([_ctx.sideBarOpened?'':'collapse']),
            src: _imports_0,
            alt: ""
          }, null, 2)),
      (_ctx.sideBarOpened)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
            (_ctx.orgName.length<=3)
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.orgName), 1))
              : (_ctx.orgName.length>=4 && _ctx.orgName.length<=5)
                ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.orgName), 1))
                : (_ctx.orgName.length>=6)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.orgName), 1))
                  : _createCommentVNode("", true)
          ], 64))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_menu_bar)
  ]))
}