<template>
  <div class="navbar">
    <div class="left-menu">
      <div class="hanburger-container">
        <Hanbuger icon="hamburger-opened"></Hanbuger>        
      </div>
      <Navgater></Navgater>
      <!-- <div class="left-menu-content">互联网医院诊间</div> -->
    </div>
    <div class="right-menu">
      <!-- <SearchText></SearchText> -->
      <!-- <Screenfull></Screenfull> -->
      <SelectTheam></SelectTheam>
      <Language></Language>
      <c-bridge :count="messageCount" @click="showMessage" style="margin-right: 30px;"></c-bridge>
      <svg-icon class="svgi" icon="setting" style="font-size: 24px;margin-right: 20px;"></svg-icon>
      <a-dropdown>
        <a class="ant-dropdown-link" @click.prevent>
          <img :src="userInfo.avatar" alt="" />
          <span class="user-name-text">{{ userInfo.nickName }}</span>
          <svg-icon icon="cart-down"></svg-icon>
        </a>
        <template #overlay>
          <a-menu>
            <a-menu-item v-if="!userInfo.ghOpenId">
              <div @click="()=>{ghopen.open=true,getQrcode(),getOpenId()}">绑定公众号</div>
            </a-menu-item>
            <a-menu-item>
              <div @click="()=>{open=true}">账号信息</div>
            </a-menu-item>
            <a-menu-item>
              <a href="#" @click="()=>{showPwdEditing=true}">修改密码</a>
            </a-menu-item>
            <a-menu-item>
              <div @click="logout">退出登录</div>
            </a-menu-item>
          </a-menu>
        </template>
      </a-dropdown>
      <!-- <svg-icon :icon="iconSetting"></svg-icon> -->
    </div>
    <a-modal v-model:visible="showPwdEditing" title="修改密码">
      <a-form ref="formInfo"
      :model="editPwdFormData"
      name="basic"
      :label-col="{style: { width: '110px' }}"
      autocomplete="off"
    >
      <a-form-item label="登录名:" name="loginName">
        <a-input readonly v-model:value="editPwdFormData.loginName"></a-input>
      </a-form-item>
      <a-form-item label="原始密码:" name="password" :help="pwdStatus.passwordHelp" :validate-status="pwdStatus.passwordStatus" :rules="[{ required: true, message: '请录入原始密码!' }]">
        <a-input-password v-model:value="editPwdFormData.password"></a-input-password>
      </a-form-item>
      <a-form-item label="新密码:" name="newPassword" :rules="[{validator: validatePassword,trigger: 'change' }]">
        <a-input-password v-model:value="editPwdFormData.newPassword"></a-input-password>
      </a-form-item>
      <a-form-item label="新密码:" name="confirmPwd" :rules="[{ validator: retryValidatePassword,trigger: 'change' }]">
        <a-input-password v-model:value="editPwdFormData.confirmPwd"></a-input-password>
      </a-form-item>
    </a-form>
    <template #footer>
        <a-button type="primary" @click="updatePwd">确定</a-button><a-button @click="()=>{showPwdEditing=false}">取消</a-button>
      </template>
    </a-modal>
    <a-modal v-model:visible="ghopen.open" :width="300" title="绑定微信公众号">
    <div>         
      <div v-if="!qrcodeUrl"><loading-outlined style="font-size:18px;color:green;" />&nbsp; 正在生成二维码……</div>
      <img v-else style="width:250px" :src="qrcodeUrl" alt="">   
    </div>
    <div style="text-align:center" v-if="ghopen.msg"><svg-icon style="font-size:16px" icon="success"></svg-icon><span>扫码绑定成功</span></div>
    <div style="text-align:center" v-else-if="qrcodeUrl">绑定公众号接收消息提醒</div>
    </a-modal>
    <a-modal v-model:visible="open" title="账号信息">
      <div class="account-details">
        <div class="detail-row"><div>登录账号</div><div>{{userInfo.loginName}}</div></div>
        <div class="detail-row"><div>昵称</div><div>{{userInfo.nickName}}</div></div>
        <div class="detail-row"><div>手机号</div><div>{{userInfo.phone}}</div></div>
        <div class="detail-row"><div>角色</div><div>{{userInfo.roleName}}</div></div>      
        <div class="detail-row"><div>创建时间</div><div>{{dayjs(userInfo.createTime).format('YYYY-MM-DD HH:mm:ss')}}</div></div>
        <div class="detail-row"><div>登录时间</div><div>{{dayjs(userInfo.lastLoginTime).format('YYYY-MM-DD HH:mm:ss')}}</div></div>
        <div class="detail-row"><div>所属机构</div><div>{{userInfo.orgName}}</div></div>
        <div class="detail-row"><div>机构类型</div><div>{{convertOrgType(userInfo.orgType)}}</div></div> 
      </div>
      <template #footer>
        <div  class="client-commands">
          <a-button @click="()=>{open=false}" type="primary">确定</a-button> 
          <a-button @click="()=>{open=false}">取消</a-button>
        </div>
      </template>
    </a-modal>
  </div>
</template>
<script lang="ts">
import { defineComponent, ref,computed,onMounted } from 'vue'
import store from '@/store'
import router from '@/router'
import orderRequest from '@/api/orders'
import Screenfull from './Screenfull.vue'
import {message} from 'ant-design-vue'
import Hanbuger from './Hanbuger.vue'
import { OrgType } from '@/Interfaces/system/audit';
import sysRequest from '@/api/sys'
import Navgater from './Navgater.vue'
import Language from './Language.vue'
import dayjs from 'dayjs'
import SelectTheam from './SelectTheam.vue'
import CBridge from '@/components/cBirdge/CBridge.vue'
import {LoadingOutlined} from '@ant-design/icons-vue';
export default defineComponent({
  setup() {
    const iconSetting = ref<string>('link')
    const formInfo=ref()
    const logout = () => {
      store.dispatch('user/logout')
      router.push('/login')
    }
    const showPwdEditing=ref(false)
    const convertOrgType=(type)=>{
      return OrgType[type] as string
    }
    const showMessage=()=>{
      router.push('/system/messages')
    }
    const ghopen=ref({open:false,msg:""})
    const pwdStatus=ref({passwordHelp:'',passwordStatus:''})
    const editPwdFormData=ref({loginName:'',password:'',newPassword:'',confirmPwd:''})
    const open = ref<boolean>(false)
    const userInfo = computed(() => store.state.user.userInfo)
    const  validatePassword=(rule, value, callback)=> {
      // 正则表达式，要求密码至少6个字符，至少一个数字，一个小写和一个大写字母  
      const regex = /^(?=.*[0-9])(?=.*[a-zA-Z]).{6,}$/;  
      if (value === '') {  
        callback(new Error('请录入新密码!'));  
      } else if (!regex.test(value)) {  
        callback(new Error('密码必须6位，且包含字母与数字。'));  
      } else {  
        callback();  
      }
    }
    const retryValidatePassword=(rule, value, callback)=>{
      const regex = /^(?=.*[0-9])(?=.*[a-zA-Z]).{6,}$/; 
      if (value === '') {  
        callback(new Error('请再录入一新新密码!'));  
      } else if (!regex.test(value)) {  
        callback(new Error('密码必须6位，且包含字母与数字。'));  
      } else if(editPwdFormData.value.newPassword!=value){  
        callback(new Error('两次密码不一致。'));  
      }else{
        callback();  
      }
    }
    const updatePwd=()=>{
      if(formInfo.value){
        formInfo.value.validate().then(res=>{
          sysRequest.upateOperatorPwd({...res,id:store.getters.userInfo.id}).then(res=>{
            if(res.data>0){
              message.success('密码修改成功')
              pwdStatus.value.passwordStatus=''
              pwdStatus.value.passwordHelp=""
            }else{
              pwdStatus.value.passwordStatus='error'
              pwdStatus.value.passwordHelp="原始密码不正确"
              message.error('密码修改失败,原始密码不正确')
            }
          })
        })
      }
    }

    const qrcodeUrl=ref('')
    const getQrcode=()=>{
      sysRequest.getQrcode(store.getters.userInfo.id).then(res=>{
        qrcodeUrl.value=res.data.qrCodeUrl
      })
    }
    const messageCount=ref(0)
    const getCountOfmessages=()=>{
      orderRequest.getCountOfmessages().then(res=>{
        messageCount.value=res.data
      })
    }
    const timeint=ref()
    const getOpenId=()=>{
      timeint.value= window.setInterval(()=>{
        sysRequest.getGhopenId(userInfo.value.id).then(res=>{
          if(res.data){
            store.state.user.userInfo.ghOpenId=res.data
            store.commit('user/setUserInfo', store.state.user.userInfo)
            ghopen.value.msg="扫码绑定成功"
            window.clearInterval(timeint.value)
            window.setTimeout(()=>{
              ghopen.value.open=false
            },1000)
          }          
        })
        if(!ghopen.value.open){
          window.clearInterval(timeint.value)
        }
      },2000)      
    }
    onMounted(()=>{
      if(router.currentRoute.value.query.type && router.currentRoute.value.query.type=='1'){
        showPwdEditing.value=true
        message.success("您的账号密码过于简单，请修改登录密码。")
      }
      store.state.app.clearMessageCount=()=>{
        messageCount.value=0
      }
      getCountOfmessages()
      editPwdFormData.value.loginName=userInfo.value.loginName
      
      // store.state.app.onsysmessage=(msg)=>{
      //   const val=JSON.parse(msg.data.replace(/'/g, '"'))
        
      //   if(val.type=="WxBinding"){
      //     store.state.user.userInfo.ghOpenId=val.openId
      //     store.commit('user/setUserInfo', store.state.user.userInfo)
      //     ghopen.value.msg="扫码绑定成功"
      //     window.setTimeout(()=>{
      //       ghopen.value.open=false
      //     },1000)
      //   }
      // }
      
    })
    return {
      iconSetting, logout,userInfo,open,dayjs,convertOrgType,showPwdEditing,updatePwd,editPwdFormData,formInfo,
      retryValidatePassword,validatePassword,pwdStatus,ghopen,qrcodeUrl,getQrcode,showMessage,messageCount,getOpenId
    }
  },
  components: {
    Hanbuger, Navgater, CBridge,SelectTheam,Language,LoadingOutlined
  }
})

</script>
<style lang="scss" scoped>
.navbar {
  // display: block;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 20px;

  .hanburger-container {
    // float: left;
    margin: 0 10px;
  }

  .left-menu {
    height: 100%;
    display: flex;
    align-items: center;

    .left-menu-content {
      font-size: 18px;
      font-weight: 600;
      margin-left: 12px;
    }
  }

  .right-menu {
    // float: right;
    height: 100%;
    display: flex;
    display: flex;
    align-items: center;
    // gap: 5px;
    margin: 0 20px;

    .ant-dropdown-link {
      color: #666666;
      font-size: 14px;
      img {
        width: 30px;
        height:30px;
        border-radius: 30px;
      }
    }
    .user-name-text{
      margin: 0 10px;
    }
    .svgi {
      width: 35px;
      height: 35px;
      padding:5px;
      cursor: pointer;
      transition: background 0.5s;
      &:hover {
        background: rgba(0, 0, 0, 0.1);
      }
    }
  }
}
.account-details{
    font-size:16px;
    .detail-row{
      display:flex;
      height:35px;
      line-height:35px;
      div:first-child{
        width:100px;
        font-weight: bold;
        margin-right:10px;
        text-align: right;
      }
    }
    .command-row{
      
      margin:10px auto;
      width:200px;
      display:flex;
      gap:10px;
      justify-content: space-around;
    }

    
  }
</style>
