import sysRequest from '@/api/sys'
import md5 from 'md5'
import { UserInfo, StateData, User,StaticData } from '@/Interfaces/user/user'
import { setItem, getItem, removeItem } from '@/utils/storege'
import { TOKEN, USER_INFO,CATEGORY_DATA,SETTINGS,STYLE_DATA,SIZE_DATA,UNITS_DATA,FUNCTIONS,FRABRIC_CATEGORY_DATA,
  DECORATION_CATEGORY_DATA,PRINTING_DATA,ACCESSORIE_CATEGORY_DATA,PROVENCES } from '@/constants'
import store from '@/store'
import router from '@/router'
import {convertEnumToArray} from "@/utils/help"
import { setTimestamp } from '@/utils/auth'
import {LogisticProvider} from '@/Interfaces/orders/EnumOrderType'
import { SelectTypes } from '@/Interfaces/user/DataItem'
// import avatarImg from '@/assets/images/avater.png'
export default {
  namespaced: true,
  state: {
    token: getItem(TOKEN) || '',
    userInfo: getItem(USER_INFO) || {},
    categoryData:getItem(CATEGORY_DATA) || [],
    accessorieCategoryData:getItem(ACCESSORIE_CATEGORY_DATA) || [],
    decorationCategoryData:getItem(DECORATION_CATEGORY_DATA) || [],
    fabricCategoryData:getItem(FRABRIC_CATEGORY_DATA) || [],
    styleData:getItem(STYLE_DATA) || [],
    sizeData:getItem(SIZE_DATA) || [],
    provences:getItem(PROVENCES) || [],
    settings:getItem(SETTINGS) || [],
    printData:getItem(PRINTING_DATA)||{},
    unitsData:getItem(UNITS_DATA) || [],
    allFunctions:getItem(FUNCTIONS) || {},
    platform:'wxaed3fcb4138c05c3',  //蓝迪云小程序APPID
    branch:'wx69ee626a1b1ee094',  //服装云定制小程序APPID
    fabric:'wx7d88041aa2fed3ca',//云端面料小程序APPID
    appId:'wxc8bdadf9af0f328b',//蓝迪云服务号APPID
    sysFunctions:getItem("sysFunctions") || [], 
    getAppletType:(appid)=>{
      return appid==='wxaed3fcb4138c05c3'?1:appid==='wx69ee626a1b1ee094'?2:appid==='wx7d88041aa2fed3ca'?3:4
    },
    logisticsProviders:convertEnumToArray(LogisticProvider)
  },
  mutations: {
    setToken(state: StateData, token: string) {
      state.token = token
      setItem(TOKEN, token)
    },
    setProvence(state: StateData, provences: Array<SelectTypes>) {
      state.provences = provences
      setItem(PROVENCES, provences)
    },
    setPrintData(state: StateData, data: any) {
      state.printData = data
      setItem(PRINTING_DATA, data)
    },
    setUserInfo(state: StateData, userInfo: User) {
      state.userInfo = userInfo
      setItem(USER_INFO, userInfo)
    },
    setCategoryData(state: StateData, categorys: Array<StaticData>) {
      state.categoryData = categorys
      setItem(CATEGORY_DATA, categorys)
    },
    setAccessorieCategoryData(state: StateData, categorys: Array<StaticData>) {
      state.accessorieCategoryData = categorys
      setItem(ACCESSORIE_CATEGORY_DATA, categorys)
    },
    setDecorationCategoryData(state: StateData, categorys: Array<StaticData>) {
      state.decorationCategoryData = categorys
      setItem(DECORATION_CATEGORY_DATA, categorys)
    },
    setFabricCategoryData(state: StateData, categorys: Array<StaticData>) {
      state.fabricCategoryData = categorys
      setItem(FRABRIC_CATEGORY_DATA, categorys)
    },
    setStyleData(state: StateData, style: Array<StaticData>) {
      state.styleData = style
      setItem(STYLE_DATA, style)
    },
    setSizeData(state: StateData, size: Array<StaticData>) {
      state.sizeData = size
      setItem(SIZE_DATA, size)
    },
    setUnitsData(state: StateData, units: Array<StaticData>) {
      state.unitsData = units
      setItem(UNITS_DATA, units)
    },
    setSettings(state: StateData, settings: any) {
      state.settings = settings
      if(!state.userInfo.avatar){
        state.userInfo.avatar='/images/avatar.png'
      }else{
        const headUrl=(state.settings as any).imgurl.value
        if(state.userInfo.avatar.indexOf('http')==-1 && state.userInfo.avatar.indexOf('/images/avatar.png')==-1){
          state.userInfo.oavatar=state.userInfo.avatar
          state.userInfo.avatar= headUrl+'/'+state.userInfo.avatar
          
        }
      }      
      setItem(SETTINGS, settings)
    },
    setFunctions(state:StateData,functions:any){
      state.allFunctions=functions
      setItem(FUNCTIONS,functions)
    },
    setSysFunctions(state:StateData,functions:any){
      state.sysFunctions=functions
      setItem("sysFunctions", functions)
    },
  },
  actions: {
    async login(context: any, userinfo: UserInfo) {
      const { username, password } = userinfo
      const res: any = await sysRequest.login({
        username,
        password: password //md5(password)
      })
      const data = res.data
      setTimestamp()
      store.commit('user/setToken', data.token)
      store.dispatch('user/getSettings')      
      store.commit('user/setUserInfo', data) 
      store.dispatch('user/getUnitData')    
      store.dispatch('user/getSizeData')
      sysRequest.operationLog({eventName:'用户账户登录'})
      const parentfunc=await sysRequest.getParentFunctions(store.getters.userInfo.orgType)
      let resss:any={}
      if(store.getters.userInfo.roleId==1){        
        resss=await sysRequest.getAllFunctions(store.getters.userInfo.orgType)
      }else{
        resss=await sysRequest.getRoleFunctions({roleId:store.getters.userInfo.roleId,orgId:store.getters.userInfo.orgId})
      }
      const fs=resss.data.sort((b,a)=>{
        return b.sortIndex-a.sortIndex
      })
      const funcs=fs.filter(itm=>itm.functionType=='FUNC') || []
      const fncsss= funcs.reduce((acc,item)=>{
        if(!acc[item.functionKey]){
          acc[item.functionKey]=item
        }
        return acc
      },{})
      store.commit('user/setSysFunctions', fncsss)     
      store.commit('user/setFunctions', {parentFunc:parentfunc.data,allFunc:fs})      
      return data
    },
    async codeLogin(context: any, validation) {     
      const res=await sysRequest.backendLogin(validation.phone,validation.code)
      const data = res.data
      if(!data.avatar){
        data.avatar = require('@/assets/images/avater.png')
      }
        
      // if(!data.avatar)
      // data.avatar = '/images/avatar.png'
      if (data.url) {
        data.url = process.env.VUE_APP_BASE_API + data.url.slice(1)
      }
      setTimestamp()
      store.commit('user/setToken', data.token)
      store.dispatch('user/getSettings')      
      store.commit('user/setUserInfo', data)
      store.dispatch('user/getUnitData')    
      sysRequest.operationLog({eventName:'用户验证码登录'})
      const parentfunc=await sysRequest.getParentFunctions(store.getters.userInfo.orgType)
      let resss:any={}
      if(store.getters.userInfo.roleId==1){        
        resss=await sysRequest.getAllFunctions(store.getters.userInfo.orgType)
      }else{
        resss=await sysRequest.getRoleFunctions({roleId:store.getters.userInfo.roleId,orgId:store.getters.userInfo.orgId})
      }
      const fs=resss.data.sort((b,a)=>{
        return b.sortIndex-a.sortIndex
      })
      const funcs=fs.filter(itm=>itm.functionType=='FUNC') || []
      const fncsss= funcs.reduce((acc,item)=>{
        if(!acc[item.functionKey]){
          acc[item.functionKey]=item
        }
        return acc
      },{})
      store.commit('user/setSysFunctions', fncsss)     
      store.commit('user/setFunctions', {parentFunc:parentfunc.data,allFunc:fs})      
      return data      
    },
    async getProvence(context: any) {
      const res = await sysRequest.getProvence()
      const op:Array<any>=[]
      res.data.forEach(itm =>{          
        op.push({label:itm.cityName,value:itm.cityCode,sp:itm.inputCode})
      });
      store.commit('user/setProvence', op)
      return op
    },
    async getSettings(context: any) {
      const res = await sysRequest.getSettings()
      const url= await sysRequest.getUrlHeader()
      const op:Array<any>=[]
      res.data.forEach(itm =>{          
        op.push(itm)
      });
      
      op.push({id:0,key:'imgurl',name:'OSS地址配置',desc:'',value:url.data,})
      const sett=op.reduce((calc,item)=>{
        if(!calc[item.key]){
          calc[item.key]=item
        }
        return calc
      },{})
      store.commit('user/setSettings', sett)
      return op
    },
    async getUserInfo(context: any) {
      const res = await sysRequest.getUserInfo()
      store.commit('user/setUserInfo', res)
      return res
    },
    async getCategoryData(context:any){
      const res = await sysRequest.getStaticData(1)  //type1服装类别，2服装风格，3商品单位,4面料分类，5饰品分类，6辅料分类
      store.commit('user/setCategoryData', res.data)

      return res.data
    },
    async getFabricCategoryData(context:any){
      const res = await sysRequest.getStaticData(4)  //type1服装类别，2服装风格，3商品单位,4面料分类，5饰品分类，6辅料分类
      store.commit('user/setFabricCategoryData', res.data)
      return res.data
    },
    async getDecorationCategoryData(context:any){
      const res = await sysRequest.getStaticData(5)  //type1服装类别，2服装风格，3商品单位,4面料分类，5饰品分类，6辅料分类
      store.commit('user/setDecorationCategoryData', res.data)
      return res.data
    },
    async getAccessorieCategoryData(context:any){
      const res = await sysRequest.getStaticData(6)  //type1服装类别，2服装风格，3商品单位,4面料分类，5饰品分类，6辅料分类
      store.commit('user/setAccessorieCategoryData', res.data)
      return res.data
    },
    async getStyleData(context:any){
      const res = await sysRequest.getStaticData(2) //type1服装类别，2服装风格，3商品单位
      store.commit('user/setStyleData', res.data)
      return res.data
    },
    async getUnitData(context:any){
      const res = await sysRequest.getUnits() //type1服装类别，2服装风格，3商品单位
      store.commit('user/setUnitsData', res.data)
      return res.data
    },
    async getSizeData(context:any){
      const res = await sysRequest.getSize(context.state.userInfo.orgId==1000?0:context.state.userInfo.orgId) //0全部，Orgid具体机构的
      store.commit('user/setSizeData', res.data)
      return res.data
    },
    logout() {
      store.commit('user/setToken', '')
      store.commit('user/setUserInfo', {})
      removeItem(TOKEN)
      router.push('/login')
    }
  }
}
//npm i --save-dev @types/md5
