<template>
  <div class="wraper">
    <div class="logo">
      <!-- <img v-if="store.getters.sideBarOpened"  src="@/assets/images/logo-white.png" alt="" /> -->
      <img :class="[sideBarOpened?'':'collapse']" v-if="orgUrl" :src="orgUrl" alt="" onerror="this.src='/images/logo-short.png'">
      <img :class="[sideBarOpened?'':'collapse']" v-else src="@/assets/images/logo-short.png" alt="">
      <template v-if="sideBarOpened">
        <div v-if="orgName.length<=3" class="short">{{orgName}}</div>
        <div v-else-if="orgName.length>=4 && orgName.length<=5" class="long">{{orgName}}</div>
        <div v-else-if="orgName.length>=6" class="long1">{{orgName}}</div>
      </template>
    </div>
    <menu-bar></menu-bar>
  </div>
</template>
<script lang="ts">
import MenuBar from './MenuBar.vue'
import store from '@/store'
import { defineComponent,computed } from 'vue'
export default defineComponent({
  components: {
    'menu-bar': MenuBar,
  },
  setup(){
    const logoHeight = 40
    const sideBarOpened=computed(()=>{
      return store.getters.sideBarOpened
    })
    const orgName=computed(()=>{
      return store.getters.userInfo.orgShortName?store.getters.userInfo.orgShortName:""
    })
    const orgUrl=computed(()=>{
      return store.getters.userInfo.logoUrl?"https://allfiles.landiyun.com/"+store.getters.userInfo.logoUrl+'?x-oss-process=image/resize,w_200,m_lfit':''
    })
    return {
      store,logoHeight,orgName,orgUrl,sideBarOpened
    }
  }
})

</script>
<style lang="scss" scoped>
.wraper {
  .logo {
    display: flex;
    align-items: center;
    margin-left: 10px;
    color: white;
    height: 70px;
    overflow: hidden;

    img {
      height: 50px;
      margin: 0 5px;
    }

    .short {
      font-size: 30px;
      
      // font-weight: 600;
      // margin-left: 10px;
      margin-top:-3px;
    }
    .long{
      font-size:24px;
      // margin-left: 10px;
      margin-top:-3px;
    }
    .long1{
      font-size:20px;
      // margin-left: 10px;
      margin-top:-3px;
    }
    .collapse{
      margin-left:13px;
    }
  }
}
</style>
