<template>
  <div class="siderbar-menu">
    <a-menu
      mode="inline"
      theme="dark"
      :inline-collapsed="!store.getters.sideBarOpened"
      v-model:openKeys="openKeys"
      v-model:selectedKeys="selectedKeys"
    >
    <template  v-for="(menu,index) in menus" >
      <a-sub-menu v-if="menu.children && menu.children.length>0"  :key="menu.path">
        <template #icon>
          <svg-icon  :class="[store.getters.sideBarOpened?'menu-icon':'plus-menu-icon']" :icon="menu.icon"></svg-icon> 
        </template>
        <template #title>{{menu.title}}</template>
        <a-menu-item v-for="(submenu,sindex) in menu.children"  @click="menuClick(submenu,'ss'+sindex)" :key="submenu.path">{{submenu.title}}</a-menu-item>
        
      </a-sub-menu>
      <a-menu-item v-else :key="'ss'+menu.path" @click="menuClick(menu,'k'+index)">
        <template #icon>          
              <svg-icon  :class="[store.getters.sideBarOpened?'menu-icon':'plus-menu-icon']" :icon="menu.icon"></svg-icon>          
        </template>
        <span>{{menu.title}}</span>
      </a-menu-item>      
    </template>  
    </a-menu>
    <div class="org-info"><div>{{userInfo.orgName}}</div><div>({{ userInfo.roleName }})</div></div>
  </div>
</template>
<script lang="ts">
import { useRouter, useRoute } from 'vue-router'
import { defineComponent, ref,reactive,toRefs,watch, computed, onMounted } from 'vue'
import { getMenus } from '@/utils/route'
import { generateTitle } from '@/utils/lang'
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  PieChartOutlined,
  MailOutlined,
  DesktopOutlined,
  InboxOutlined,
  AppstoreOutlined,
} from '@ant-design/icons-vue';
import store from '@/store'
import sysRequest from '@/api/sys'
import components from '@/components/index';
type MenuItem = {
  title:string,
  path:string,
  icon:string,
  expaned?:boolean,
  children?:Array<MenuItem>
}
export default defineComponent({
  setup() {
    const router = useRouter()
    const route = useRoute()
    const routes = getMenus(router.getRoutes())
    const openKeys = ref<string[]>([])
      const state = reactive({
      collapsed: false,
      selectedKeys: ['/platform/platforminfo'],
      openKeys: ['/platform/platforminfo'],
      preOpenKeys: ['sub1'],
    });
    const menuClick = (menu:MenuItem,key:string) => {
      if (!menu.children) {
        if(menu.path==='/products/bokes'){
          router.push({  
            path: menu.path,  
            query: {productType:0}
          });
        }else{
          router.push(menu.path)
        }
        
      }
    }
    const userInfo=computed(()=>{
      return store.getters.userInfo
    })
      
    watch(
      () => state.openKeys,
      (val, oldVal) => {
        state.preOpenKeys = oldVal;
      },
    );
    const menus = ref<MenuItem[]>([
    {title:generateTitle('platmanagemtnt'),path:'/app',icon:'base-data',children:[
      {title:'平台信息',path:'/platform/platforminfo',icon:'personnel-manage'},
      {title:'分店清单',path:'/platform/branchlist',icon:'personnel-manage'},
      {title:'面料商清单',path:'/platform/fabriclist',icon:'personnel-manage'},
      {title:'入驻版师清单',path:'/platform/designers',icon:'personnel-manage'},
      {title:'成衣生产厂商',path:'/platform/manufacturers',icon:'personnel-manage'},
      {title:'平台账号',path:'/platform/accounts',icon:'personnel-manage'},
      {title:'角色权限',path:'/platform/rights',icon:'personnel-manage'},
      {title:'客户反馈',path:'/platform/feedback',icon:'personnel-manage'},
      {title:'客户的杂图',path:'/platform/images',icon:'personnel-manage'},
      {title:'分店数据初始化',path:'/platform/datainit',icon:'personnel-manage'},
      {title:'共享版型图片',path:'/platform/sharedimgs',icon:'personnel-manage'},
    ]},
    {title:'客户管理',path:'/customers',icon:'personnel',children:[ 
      {title:'客户管理',path:'/customers/customers',icon:'personnel-manage',expaned:true},
      {title:'会员等级',path:'/customers/levels',icon:'personnel-manage',expaned:true},
      {title:'平台优惠券',path:'/customers/notes',icon:'personnel-manage',expaned:true},
      {title:'续期审核',path:'/customers/timeextention',icon:'personnel-manage',expaned:true},
     
    ]},
    {title:'审核管理',path:'/audited',icon:'audit',children:[ 
      {title:'版型审核',path:'/audited/bokes',icon:'personnel-manage',expaned:true},
      {title:'面料审核',path:'/audited/fabrics',icon:'personnel-manage',expaned:true},
      {title:'饰品审核',path:'/audited/decorations',icon:'personnel-manage',expaned:true},
      // {title:'量体版型审核',path:'/audited/timeextention',icon:'personnel-manage',expaned:true},
      {title:'版型共享审核',path:'/audited/shared',icon:'personnel-manage',expaned:true},
      {title:'版师引用审核',path:'/audited/ref',icon:'personnel-manage',expaned:true},
      {title:'审核设置',path:'/audited/settings',icon:'personnel-manage',expaned:true},     
    ]},
    {title:'商品管理',path:'/products/products',icon:'cloth',children:[ 
      {title:'版型管理',path:'/products/bokes',icon:'personnel-manage',expaned:true},
      {title:'分店版型',path:'/products/branchProducts',icon:'personnel-manage',expaned:true},
      {title:'成衣管理',path:'/products/cloths',icon:'personnel-manage',expaned:true},
      {title:'面料管理',path:'/products/fabrics',icon:'personnel-manage',expaned:true},
      {title:'辅料管理',path:'/products/accessories?type=2',icon:'personnel-manage',expaned:true},
      {title:'饰品管理',path:'/products/decoration?type=3',icon:'personnel-manage',expaned:true},      
      {title:'批量绑定',path:'/products/binding',icon:'personnel-manage',expaned:true},
    ]},
    {title:generateTitle('ordersTitle'),path:'/orders/products',icon:'article',children:[ 
      {title:generateTitle('orders.bokesOrder'),path:'/orders/bokes',icon:'personnel-manage',expaned:true},
      {title:generateTitle('orders.physicalBokes'),path:'/orders/physicalBokes',icon:'personnel-manage',expaned:true},
      {title:generateTitle('orders.fabricsOrder'),path:'/orders/fabrics',icon:'personnel-manage',expaned:true},
      {title:generateTitle('orders.decorationOders'),path:'/orders/decoration',icon:'personnel-manage',expaned:true},
      {title:generateTitle('orders.tailoredOrders'),path:'/orders/tailored',icon:'personnel-manage',expaned:true},
      {title:generateTitle('orders.tailoredBokes'),path:'/orders/tailoredBokes',icon:'personnel-manage',expaned:true},
      {title:generateTitle('orders.tailoredMade'),path:'/orders/tailoredMade',icon:'personnel-manage',expaned:true},
      {title:generateTitle('orders.tailoredCloths'),path:'/orders/tailoredCloths',icon:'personnel-manage',expaned:true},
      {title:generateTitle('orders.clothsOrders'),path:'/orders/cloths',icon:'personnel-manage',expaned:true},
      {title:generateTitle('orders.videoTeching'),path:'/orders/videoTeching',icon:'personnel-manage',expaned:true},
      {title:generateTitle('orders.physicalPrinting'),path:'/orders/physicalPrinting',icon:'personnel-manage',expaned:true},
      
    ]},
    {title:'库存管理',path:'/inventory',icon:'setting1',expaned:true,children:[ 
    {title:'商品库存',path:'/inventory/storage',icon:'personnel-manage',expaned:true},
    {title:'入库详情',path:'/inventory/instore',icon:'personnel-manage',expaned:true},    
    {title:'出库详情',path:'/inventory/outstore',icon:'personnel-manage',expaned:true},   
    ]},
    {title:'财务管理',path:'/financial',icon:'setting1',expaned:true,children:[ 
    {title:'充值记录',path:'/financial/recharge',icon:'personnel-manage',expaned:true},
    {title:'流水记录',path:'/financial/accountStatements',icon:'personnel-manage',expaned:true},     
    ]},
    {title:'系统设置',path:'/syssettings',icon:'setting1',expaned:true,children:[ 
    {title:'服装类别',path:'/system/clothCategory',icon:'personnel-manage',expaned:true},
    {title:'服装风格',path:'/system/clothStyle',icon:'personnel-manage',expaned:true},    
    {title:'服装尺码',path:'/system/clothSize',icon:'personnel-manage',expaned:true},
    {title:'教学视频',path:'/system/videos',icon:'personnel-manage',expaned:true},
    {title:'商品单位',path:'/system/productUnit',icon:'personnel-manage',expaned:true},
    {title:'系统参数',path:'/system/settings',icon:'personnel-manage',expaned:true},
    ]},

    {title:'系统图标',path:'/platform/icons',icon:'setting1'}])    
    const openMenu = (menu) => {
      let index = openKeys.value.indexOf(menu.path)
      if (index != -1) {
        openKeys.value.splice(index, 1)
      } else {
        openKeys.value = [menu.path]
      }
    }
    const expaned = (menu) => {
      menu.expaned=!menu.expaned;
    }
    const getAllFunctions =async () =>{
        const funcs=[...store.getters.functions.parentFunc]
        store.getters.functions.allFunc.forEach(itm=>{
          if(itm.functionType=='MENU'){
            funcs.push(itm)
          }         
        })
        menus.value=funcs.reduce((acc,item)=>{          
          if(item.parentId==0){
            acc.push({title:item.functionName,path:item.functionUrl,icon:item.icon,parentId:item.parentId,id:item.id})
          }else{
            const itm=acc.find(itm=>itm.id==item.parentId)
            if(itm){
              if(!itm.children)
                itm.children=[]
              itm.children.push({title:item.functionName,path:item.functionUrl,icon:item.icon,parentId:item.parentId,id:item.id})
            }
          }
          return acc
        },[])  
        for(let i=0;i<menus.value.length;i++){
          if(!menus.value[i].children || menus.value[i].children?.length==0){
            menus.value.splice(i,1)
            i--;
          }
        } 
    }
    const getRoleFunctions = async (roleId) => {      
      const fs=store.getters.functions.allFunc?.sort((b,a)=>{
          return b.sortIndex-a.sortIndex
        })
        const funcs=[...store.getters.functions.parentFunc]
        fs.forEach(itm=>{
          if(itm.functionType=='MENU'){
            funcs.push(itm)
          }        
        })
        menus.value=funcs.reduce((acc,item)=>{          
          if(item.parentId==0){
            acc.push({title:item.functionName,path:item.functionUrl,icon:item.icon,parentId:item.parentId,id:item.id})
          }else{
            const itm=acc.find(itm=>itm.id==item.parentId)
            if(itm){
              if(!itm.children)
                itm.children=[]
              itm.children.push({title:item.functionName,path:item.functionUrl,icon:item.icon,parentId:item.parentId,id:item.id})
            }
          }
          return acc
        },[])    
        for(let i=0;i<menus.value.length;i++){
          if(!menus.value[i].children || menus.value[i].children?.length==0){
            menus.value.splice(i,1)
            i--;
          }
        }  
    }
    onMounted(() => {
      if(store.getters.userInfo.roleId==1){  //如果是管理员，直接取所有菜单
        getAllFunctions()
      }else{
        getRoleFunctions(store.getters.userInfo.roleId)
      }
    })
    return {
      menuClick, ...toRefs(state),openMenu, store, generateTitle, openKeys, routes, route,menus,expaned,userInfo
    }
  },
  components:{
    MenuFoldOutlined,
    MenuUnfoldOutlined,
    PieChartOutlined,
    MailOutlined,
    DesktopOutlined,
    InboxOutlined,
    AppstoreOutlined,
  }
})

</script>

<style lang="scss" scoped>
@import '~@/assets/styles/variables.module.scss';
.siderbar-menu {
  cursor: pointer;
  height:calc(100vh - 100px);
  overflow-y:auto;
  .org-info{
    color:lightgray;
    position: fixed;
    bottom:5px;
    display:flex;
    flex-wrap: wrap;
    width:var(--sideBarWidth);
    left:5px;
  }
  .siderbar-submenu {
    position: relative;
    padding: 8px 0;
  }

  .siderbar-menu-item {
    position: relative;
    box-sizing: border-box;
    background-color: $menuBg;
    color: #fff;
    &:first-child {
      margin-top: 0;
    }
  }

  .siderbar-menu-item-title {
    box-sizing: border-box;
    font-size: 15px;
    height: 45px;
    line-height: 45px;
    white-space: nowrap;
  }
  .siderbar-submenu-item-title{
    box-sizing: border-box;
    font-size: 15px;
    height: 45px;
    line-height: 45px;
    white-space: nowrap;
    margin-left:20px;
  }
  .siderbar-menu-title-content {
    margin-left: 10px;
  }

  .siderbar-child-menu-title-content {
    margin-left: 48px;
  }

  .active-menu-item {   
    color: white;
    .siderbar-menu-item-title {
      
      background: #1890ff;
    }
  }

  .menu-icon {
    font-size: 18px !important;
  }

  .plus-menu-icon {
    font-size: 24px !important;
    
  }
  
  .right-arrow-icon {
    position: absolute;
    right: 12px;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.1s cubic-bezier(1, 0.5, 0.8, 1);
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
