import request from '@/utils/request'
import store from '@/store'
export default {
  operationLog:(data)=> {
    return request({
      url: 'system/operationLog',
      method: 'POST',
      data
    }) 
  },
  udpateWxPayments:(data)=> {
    return request({
      url: 'system/udpateWxPayments',
      method: 'POST',
      data
    }) 
  },
  sendWxTemplateMsg:(data)=> {
    return request({
      url: 'system/sendWxChatMsg',
      method: 'POST',
      data
    }) 
  },
  
  sendValideCode:(phone,token)=>{
    return request({
      url: `land/sendBackendValideCode?token=${token}${new Date().getTime()}&phone=${phone}`,
      method: 'GET',
    })
  },
  backendLogin:(phone,code)=>{
    return request({
      url: `land/backendLogin?phone=${phone}&code=${code}`,
      method: 'GET',
    })
  },
  getClientById:(id)=>{
    return request({
      url: `system/clientById?id=${id}`,
      method: 'GET',
    })
  },
  getAuditedRecord:(id)=>{
    return request({
      url: `system/auditedRecord?productId=${id}`,
      method: 'GET',
    })
  },
  bindOperatorCustomerId:({customerId,operatorId,orgId})=>{
    return request({
      url: `system/bindOperatorCustomerId?customerId=${customerId}&id=${operatorId}&orgId=${orgId}`,
      method: 'GET',
    })
  },
  getClientsByPhone:(phone,orgId)=>{
    return request({
      url: `system/clientsByPhone?orgId=${orgId}&cellPhone=${phone}`,
      method: 'GET',
    })
  },
  setParentOrgId:(parentId,orgId)=>{
    return request({
      url: `system/setOrgParentId?orgId=${orgId}&parentId=${parentId}`,
      method: 'GET',
    })
  },
  updateOrgStatus:(id,status)=>{
    return request({
      url: `system/updateOrgStatus?id=${id}&status=${status}`,
      method: 'GET',
    })
  },
  updateReceiveOrderMessage:(id,val)=>{
    return request({
      url: `system/updateReceiveOrderMessage?id=${id}&receiveOrderMessage=${val}`,
      method: 'GET',
    })
  },
  updateReceiveAuditMessage:(id,val)=>{
    return request({
      url: `system/updateReceiveAuditMessage?id=${id}&receiveAuditMessage=${val}`,
      method: 'GET',
    })
  },
  getOperationLogs:(data)=> {
    return request({
      url: 'system/operationLogs',
      method: 'POST',
      data
    }) 
  },
  login: (data: any) => {
    return request({
      url: 'auth/login',
      method: 'POST',
      data
    })
  },
  saveFunction: (data: any) => {
    return request({
      url: 'auth/function',
      method: 'POST',
      data
    })
  },
  getUserInfo: () => {
    return request({
      url: 'sys/profile',
      method: 'GET',
    })
  },
  getNotice:(orgId)=>{
    return request({
      url: `system/notices?orgId=${orgId}`,
      method: 'GET',
    })
  },
  saveNotice:(data)=>{
    return request({
      url: `system/notice`,
      method: 'POST',
      data
    })
  },
  updateOrgOrderLimit: (orderLimit,id) => {
    return request({
      url: `system/updateOrgOrderLimit?orderLimit=${orderLimit}&id=${id}`,
      method: 'GET',
    })
  },
  getGhopenId:(id)=>{
    return request({
      url: `system/ghopenId?id=${id}`,
      method: 'GET',
    })
  },
  getQrcode: (id) => {
    const myHeaders = new Headers();
    myHeaders.append("token", store.getters.token);
      const requestOptions = {
        method: 'GET',
        headers: myHeaders,
      };
      return fetch(`${store.getters.settings['ApiUrl'].value}/wx/qrCode?clientId=${id}`, requestOptions as any)
        .then(response => {
          if (!response.ok) {
            throw new Error(`网络错误！状态码: ${response.status}`);
          }
          return response.json();
        })
        .catch(error => {throw error});
    // return request({
    //   url: `${store.getters.settings['ApiUrl'].value}/wx/qrCode?clientId=${id}`,
    //   method: 'GET',
    // })
  },
  
  getOrderLimitAmount: (id) => {
    return request({
      url: `system/orderLimitAmount?id=${id}`,
      method: 'GET',
    })
  },
  //添加系统信息
  insertSysMessages: (data) => {
    return request({
      url: 'system/insertSysMessages',
      method: 'POST',
      data,
    })
  },
  //查询所有系统信息
  getSysMessages: () => {
    return request({
      url: 'system/sysMessages',
      method: 'GET',
    })
  },
  insertSysOperator: (data) => {
    return request({
      url: 'system/addOperator',
      method: 'POST',
      data
    })
  },
  editOperator: (data) => {
    return request({
      url: 'system/editOperator',
      method: 'POST',
      data
    })
  },  
  sysOperatorBySearch: (data) => {
    return request({
      url: 'system/operators',
      method: 'POST',
      data
    })
  },
  
  getRoles: (orgId) => {
    return request({
      url: 'system/roles/'+orgId,
      method: 'GET',
    })
  },
  saveSizeTemplate: (data) => {
    return request({
      url: 'system/sizeTemplate',
      method: 'POST',
      data
    })
  },
  getSizeTemplates: () => {
    return request({
      url: 'system/sizeTemplates',
      method: 'GET'      
    })
  },
  getSizeTemplateById: (id) => {
    return request({
      url: `system/sizeTemplate?id=${id}`,
      method: 'GET'      
    })
  },
  delSizeTemplateById: (id) => {
    return request({
      url: `system/sizeTemplate?id=${id}`,
      method: 'DELETE'      
    })
  },
  addOrg:(data) => {
    return request({
      url: 'system/addOrg',
      method: 'POST',
      data
    })
  },
  getHostnameByorgId: (orgId) => {
    return request({
      url: `system/hostnameByorgId?orgId=${orgId}`,
      method: 'GET'      
    })
  },
  
  editOrg:(data) =>{
    return request({
      url: 'system/editOrg',
      method: 'POST',
      data
    })
  },
  getLevels:(orgId) =>{
    return request({
      url: 'system/allLevels',
      method: 'POST',
      data:{orgId}
    })
  },
  addRole:(role)=>{
    return request({
      url: 'system/addRole',
      method: 'POST',
      data:role
    }) 
  },
  setOrgGroupId:(role)=>{
    return request({
      url: 'system/setOrgGroupId',
      method: 'POST',
      data:role
    }) 
  },  
  setMainOrg:(role)=>{
    return request({
      url: 'system/setMainOrg',
      method: 'POST',
      data:role
    }) 
  },  
  editOrgOther:(data)=>{
    return request({
      url: 'system/addEditOrgOther',
      method: 'POST',
      data:data
    }) 
  },
  saveSysLevels:(data)=>{
    return request({
      url: 'system/editSysLevels',
      method: 'POST',
      data:data
    }) 
  },
  getLevelLists:(orgid)=>{
    return request({
      url: 'system/levels/'+orgid,
      method: 'GET'
    }) 
  },
  initOrgProductData:({orgId,type})=>{
    return request({
      url: `system/initOrgProductData?orgId=${orgId}&type=${type}`,
      method: 'GET'
    }) 
  },
  iniOrgAdmin:(orgId)=>{
    return request({
      url: `system/iniOrgAdmin?orgId=${orgId}`,
      method: 'GET'
    }) 
  },
  getOrgOther:(id)=>{
    return request({
      url: 'system/orgOther/'+id,
      method: 'GET'
    }) 
  },
  deleteRole:(role)=>{
    return request({
      url: 'system/deleteRole',
      method: 'DELETE',
      data:role
    }) 
  },
  deleteRoleFunction:(id)=>{
    return request({
      url: 'system/deleteRoleFunction/'+id,
      method: 'DELETE'
    }) 
  },
  deleteBrand:(id)=>{
    return request({
      url: 'system/deleteBrand/'+id,
      method: 'DELETE'
    }) 
  },
  addBrand:(data) => {
    return request({
      url: 'system/addBrand',
      method: 'POST',
      data
    }) 
  },
  updateSysOperatorDisable: (data) => {
    return request({
      url: 'system/editOperatorDisable',
      method: 'POST',
      data
    })
  },
  saveOrgSettings: (data) => {
    return request({
      url: 'system/orgSettings',
      method: 'POST',
      data
    })
  },
  updateOrgAdminInfo: (data) => {
    return request({
      url: 'system/updateOrgAdminInfo',
      method: 'POST',
      data
    })
  },
  getOrgInfo:(id)=>{
    return request({
      url: 'system/org/'+id,
      method: 'GET',
    })
  },
  getOrgInfoByType:(id)=>{
    return request({
      url: 'system/orgs/'+id,
      method: 'GET',
    })
  },
  saveProfitSharing:(id,percent)=>{
    return request({
      url: `system/saveProfitSharing?id=${id}&percent=${percent}`,
      method: 'get',
    })
  },
  getFeedbacks:(start,end)=>{
    return request({
      url: `client/advicesBytime?start=${start}&end=${end}`,
      method: 'GET',
    })
  },
  advicesResponse:(data)=>{
    return request({
      url: `client/advicesResponse`,
      method: 'POST',
      data
    })
  },
  getOrgByroles:(roles)=>{
    return request({
      url: 'system/orgsByroles',
      method: 'POST',
      data:roles
    })
  },
  getNonePaymentOrgs:()=>{
    return request({
      url: 'system/nonePaymentOrgs',
      method: 'GET'
    })
  },  
  allBrands:()=>{
    return request({
      url: 'system/allBrands',
      method: 'GET',
    })
  },
  updateOrgInfo: (data) => {    
    return request({
      url: 'system/editOrg',
      method: 'POST',
      data
    })
  },
  getAllFunctions:(orgType)=>{
    return request({
      url: 'system/allFunctions?orgType='+orgType,
      method: 'GET',
    })
  },
  resetPwd:(id)=>{
    return request({
      url: 'system/resetPwd?id='+id,
      method: 'GET',
    })
  },
  resetClientPwd:(id)=>{
    return request({
      url: 'system/resetClientPwd?id='+id,
      method: 'GET',
    })
  },
  getStaticData:(type)=>{
    return request({
      url: 'system/statics/'+type,
      method: 'GET',
    })
  },
  addStaticData:(data)=>{
    return request({
      url: 'system/addStaticData',
      method: 'POST',
      data
    })
  },
  deleteStaticData:(id)=>{
    return request({
      url: `system/staticData/${id}`,
      method: 'DELETE'
      
    })
  },
  
  getRoleFunctions:(data)=>{
    return request({
      url: 'system/functions',
      method: 'POST',
      data
    })    
  },
  upateOperatorPwd:(data)=>{
    return request({
      url: 'system/upateOperatorPwd',
      method: 'POST',
      data
    })    
  },
  getOperator:(id)=>{
    return request({
      url: 'system/operator?id='+id,
      method: 'GET'
    })    
  },
  getParentFunctions:(orgType)=>{
    return request({
      url: 'system/parentFunctions?orgType='+orgType,
      method: 'GET'
    })    
  },
  addRoleFunction:(data)=>{
    return request({
      url: 'system/addRoleFunction',
      method: 'POST',
      data
    })
    
  },
  getOrgFunctions:()=>{
    return request({
      url: 'system/orgFunctions',
      method: 'GET'
    })    
  },
  getOrgRoles:()=>{
    return request({
      url: 'system/orgRoles',
      method: 'GET'
    })    
  },
  getOrgFunctionsByRoleId:(roleId)=>{
    return request({
      url: `system/orgFunctionsByRoleId?roleId=${roleId}`,
      method: 'GET'
    })    
  },
  delOrgFunctionsById:(id)=>{
    return request({
      url: `system/orgFunctionsById?id=${id}`,
      method: 'DELETE'
    })    
  },
  insertOrgRoleFunction:(data)=>{
    return request({
      url: `system/orgRoleFunction`,
      method: 'POST',
      data
    })    
  },
  addOrgRole:(data)=>{
    return request({
      url: `system/orgRole`,
      method: 'POST',
      data
    })    
  },
  updateOrgdata:(data)=>{
    return request({
      url: `system/orgdata`,
      method: 'POST',
      data
    })    
  },
  saveSizeAttach:(data)=>{
    return request({
      url: 'system/sizeAttach',
      method: 'POST',
      data
    })
    
  },
  getSizeAttach:(id)=>{
    return request({
      url: 'system/sizeAttach/'+id,
      method: 'GET', 
    })
  },
  getDesigners:(orgId)=>{
    return request({
      url: `system/designers?orgId=${orgId}`,
      method: 'GET', 
    })
  },
  getSettings:()=>{
    return request({
      url: 'system/settings',
      method: 'GET'
    })
  },
  getProvence:()=> {
    return request({
      url: `system/provences`,
      method: 'GET',
    }) 
  },
  getOperatorsByOrgId:()=>{
    return request({
      url: 'system/operatorsByOrgId',
      method: 'GET'
    })
  },
  updateSettings:(data)=>{
    return request({
      url: 'system/settings',
      method: 'POST',
      data
    })
  },
  getAuditedSettings:(type)=>{
    return request({
      url: `system/auditSetting?type=${type}`,
      method: 'GET'
    })
  },
  saveAuditedSettings:(data)=>{
    return request({
      url: `system/auditSetting`,
      method: 'POST',
      data
    })
  },
  rewriteAuditedSettings:(data)=>{
    return request({
      url: `system/rewriteAuditSettings`,
      method: 'POST',
      data
    })
  },
  delAuditedSettings:(id)=>{
    return request({
      url: `system/auditSetting?id=${id}`,
      method: 'DELETE',
    })
  },
  getUrlHeader:()=>{
    return request({
      url: 'system/urlHeader',
      method: 'GET', 
    })
  },
  delStaticData:(id)=>{
    return request({
      url: `system/staticData?id=${id}`,
      method: 'DELETE', 
    })
  },  
  resetAdminPwd:(data)=>{
    return request({
      url: `system/adminPwd`,
      method: 'POST',
      data
    })
  },
  updateStaticData:(data)=>{
    return request({
      url: `system/staticData`,
      method: 'POST',
      data
    })
  },
  uploadImage:(data)=>{
    return request({
      url: 'system/uploadOrgImages',
      method: 'POST',
      data,
      headers: {  
        'Content-Type': 'multipart/form-data'  
      }  
    })    
  },
  getWxLoginResult:(state)=>{
    return request({
      url: `land/wxLoginResult?state=B_${state.t}&orgId=${state.orgId}`,
      method: 'GET',
    })
  },
  getHostId:(hostname)=>{
    return request({
      url: `system/hostId?hostname=${hostname}`,
      method: 'GET',
    })
  },
  bindOperatorWxLogin:(phone,openId)=>{
    return request({
      url: `land/bindOperatorWxLogin?celphone=${phone}&openId=${openId}`,
      method: 'GET',
    })
  },
  uploadImageWithName:(data)=>{
    return request({
      url: 'system/uploadImageWithName',
      method: 'POST',
      data,
      headers: {  
        'Content-Type': 'multipart/form-data'  
      }  
    })    
  },
  updateMerchId:(orgId,mchId)=>{
    return request({
      url: `system/updateMerchId?orgId=${orgId}&mchId=${mchId}`,
      method: 'GET',
    })
  },
  getMerchConfirm:(orgId)=>{
    return request({
      url: `system/merchConfig?orgId=${orgId}`,
      method: 'GET',
    })
  },
  //land/openIdByNonce?nonce=1234567
  getOpenIdByNonce:(nonce)=>{
    return request({
      url: `land/openIdByNonce?nonce=${nonce}`,
      method: 'GET',
    })
  },
  getOpenId:(nonce)=>{
    return request({
      url: `land/getOpenId?nonce=${nonce}`,
      method: 'GET',
    })
  },
  addReceivers:(data)=>{
    return request({
      url: `sftpay/addReceivers`,
      method: 'POST',
      data
    })
  },
  delReceivers:(data)=>{
    return request({
      url: `sftpay/delReceivers`,
      method: 'POST',
      data
    })
  },
  getReceiver:(orgId)=>{
    return request({
      url: `sftpay/receiver?orgId=${orgId}`,
      method: 'GET'
    })
  },
  getapplyStatus:(requestNum)=>{
    return request({
      url: `sftpay/applyStatus?requestNum=${requestNum}`,
      method: 'GET'
    })
  },
  saveMerchConfig:(orgId,data)=>{
    return request({
      url: `system/saveMerchConfig?orgId=${orgId}`,
      method: 'POST',
      data
    })
  },
  submitMerchConfig:(data)=>{
    return request({
      url: `sftpay/addMerch`,
      method: 'POST',
      data
    })
  },
  getBanksFromwx:(offset,limit)=>{
    return request({
      url: `sftpay/banks?offset=${offset}&limit=${limit}`,
      method: 'GET',
    })
  },
  getBanks:()=>{
    return request({
      url: `system/banks`,
      method: 'GET',
    })
  },
  saveBank:(data)=>{
    return request({
      url: `system/bank`,
      method: 'POST',
      data
    })
  },
  uploadMerchImage:(data)=>{
    return request({
      url: 'system/uploadMerchImage',
      method: 'POST',
      data,
      headers: {  
        'Content-Type': 'multipart/form-data'  
      }  
    })    
  },
  uploadVideo:(data)=>{
    return request({
      url: 'system/uploadVideo',
      method: 'POST',
      data,
      headers: {  
        'Content-Type': 'multipart/form-data'  
      }  
    })    
  },
  updateAvatar:(avatar,id)=>{
    return request({
      url: `system/updateAvatar?avatar=${avatar}&id=${id}`,
      method: 'GET', 
    })
  },
  getHomeSettings:(orgId)=>{
    return request({
      url: `system/homeSettings?orgId=${orgId}`,
      method: 'GET', 
    })
  },
  updateHomeSettings:(data)=>{
    return request({
      url: `system/homeSettings`,
      method: 'POST',
      data 
    })
  },
  uploadBokeFile:(data)=>{
    return request({
      url: 'system/uploadBokeFile',
      method: 'POST',
      data,
      headers: {  
        'Content-Type': 'multipart/form-data'  
      }  
    })
    
  },
  uploadSizeFile:(data)=>{
    return request({
      url: 'system/uploadSizeFile',
      method: 'POST',
      data,
      headers: {  
        'Content-Type': 'multipart/form-data'  
      }  
    })
    
  },
  
  getSize:(id)=>{
    return request({
      url: 'system/size/'+id,
      method: 'GET', 
    })
  },
  getSizeGroups:()=>{
    return request({
      url: 'system/sizeGroups',
      method: 'GET', 
    })
  },
  getDepositeSettings:()=>{
    return request({
      url: `system/depositeSettings`,
      method: 'GET', 
    })
  },
  saveDepositeSettings:(data) => {
    return request({
      url: 'system/depositeSettings',
      method: 'POST',
      data
    }) 
  },
  deleteSizeGroup:(id)=>{
    return request({
      url: 'system/deleteSizeGroup/'+id,
      method: 'DELETE', 
    })
  },
  addSizeGroup:(data) => {
    return request({
      url: 'system/addSizeGroup',
      method: 'POST',
      data
    }) 
  },
  addSizeData:(data) => {
    return request({
      url: 'system/addSizeData',
      method: 'POST',
      data
    }) 
  },
  editSizeData:(data) => {
    return request({
      url: 'system/editSizeData',
      method: 'POST',
      data
    }) 
  },
  deleteSizeData:(id)=> {
    return request({
      url: 'system/deleteSizeData/'+id,
      method: 'DELETE',
    }) 
  },
  deleteUnit:(id)=> {
    return request({
      url: 'system/unit/'+id,
      method: 'DELETE',
    }) 
  },
  getUnits:()=> {
    return request({
      url: 'system/units',
      method: 'GET',
    }) 
  },
  saveUnit:(data)=> {
    return request({
      url: 'system/unit',
      method: 'POST',
      data
    }) 
  },
  auditedRecord:(data)=> {
    return request({
      url: 'system/auditedRecord',
      method: 'POST',
      data
    }) 
  },
  getVideos:()=> {
    return request({
      url: 'system/videos',
      method: 'GET',
    }) 
  },
  delVideos:(id)=> {
    return request({
      url: `system/videos?id=${id}`,
      method: 'DELETE',
    }) 
  },
  saveVideo:(data)=>{
    return request({
      url: 'system/videos',
      method: 'POST',
      data
    }) 
  },
  getMemberPayments:(data)=> {
    return request({
      url: 'client/memberPayments',
      method: 'POST',
      data
    }) 
  },
  saveCourse:(data)=> {
    return request({
      url: 'system/course',
      method: 'POST',
      data
    }) 
  },
  getCourses:(orgId)=> {
    return request({
      url: `system/courses?orgId=${orgId}`,
      method: 'GET'
      
    }) 
  },
}
