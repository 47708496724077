<template>
  <div  @scroll="scrollEvent($event)" class="table-container" :style="baseStyle"  style="border-right:solid 1px #eaeaea">
    <table ref="mainTable" class="main-table" @mouseleave="mouselevel">
      <tr v-if="props.tableTitle">
        <th style="position:sticky;top:0" :colspan="columns.length">{{props.tableTitle}}</th>
      </tr>
      <tr>
        <th :colspan="cindex==0 && showSubItems?2:1" :class="[...getClassNames(col),'row-fixed', tdCenter ? 'td-center' : '']" :style="cacColumnStyle(col,true,false,cindex)" 
        v-for="(col,cindex) in cols" 
        :key="cindex">
        <div style="line-height:14px">{{ col.title }}</div>
        <slot v-if="col.slots?.showSlot" name="selectall" :column="col"></slot>
      </th>
      </tr>
      <template v-if="dataSource.length>0 ">
      <template  v-for="(row,index) in dataSource" >
        <tr v-if="row" @mouseover="handleMouseOver(index)" @click.stop="selectItem(row,index)" :key="index">          
          <td v-if="showSubItems" style="width:20px;font-size:15px;text-align: center;padding:0 3px; cursor:pointer;" :class="rowStatus.selectedIndex==index && showSelected?'selected':''">
          <svg-icon @click="expandClick(row)" v-if="row.subRows && row.subRows.length>0" :icon="row.expand?'expand':'collapse'"></svg-icon>
          </td>
          <td :style="cacColumnStyle(col,false,false,cindex)" 
          :class="[...getClassNames(col),'ellipsis',rowStatus.overIndex==index ?'mover':'', rowStatus.selectedIndex==index?'selected':'', tdCenter ? 'td-center' : '']" 
          v-for="(col,cindex) in cols" :key="cindex">
            <slot v-if="col.slots" name="bodycell" :column="col" :record="row" :rowCount="dataSource.length" :rowIndex="index" 
            :slots="col.slots" :extend="row.extendColumn"></slot>       
            <div :style="cacColumnStyle(col,false,false,cindex)" v-else-if="props.searchColumn && props.searchColumn.indexOf(col.dataIndex)>=0">
            <template v-if="row.extendColumn">
              <span v-for="(crow,cindex) in row.extendColumn[col.dataIndex]" :key="cindex">
                <span class="hightlight" v-if="props.searchText && crow===props.searchText.value">{{ crow }}</span>
                <span v-else>{{crow}}</span>
              </span>
            </template>
            <div v-else>
              {{row[col.dataIndex]}}
            </div>
            </div>          
            <div v-else-if="col.dataIndex" :style="cacColumnStyle(col,false,true,cindex)" class="ellipsis">{{ row[col.dataIndex] }}</div>
          </td>
        </tr>
        <tr v-if="showSubItems && row.subRows && row.subRows.length>0 && row.expand" :key="'subRows'+index">
          <td  :colspan="cols.length" style="padding:5px 0;">
            <table v-if="subTable" class="main-table" style="margin-left:10px;border-left:solid 3px green;width:100%" 
            @mouseleave="submouselevel(row)">
              <tr v-if="subTable.tableTitle">
                <th style="position:sticky;top:0" :colspan="subTable && subTable.columns && columns.length">{{subTable.tableTitle}}</th>
              </tr>
              <tr v-if="subTable.showHeader">
                <th :class="[...getClassNames(subcol),'row-fixed', tdCenter ? 'td-center' : '']" 
                :style="subcacColumnStyle(subcol,true,false,cindex)" v-for="(subcol,cindex) in subColumns" 
                :key="cindex"><div style="height:14px;line-height: 14px;">{{ subcol.title }}</div></th>
              </tr>
              <template v-if="row.subRows.length>0 ">
              <template  v-for="(subRow,subIndex) in row.subRows" >
                <tr v-if="subRow" @mouseover="subhandleMouseOver(row,subIndex)" @click="subselectItem(row,subRow,subIndex)" :key="subIndex">          
                  <td class="th-background" :style="subcacColumnStyle(subCol,false,false,cindex)" 
                  :class="[...getClassNames(subCol),'ellipsis',row.subrowStatus?.overIndex==subIndex ?'mover':'', row.subrowStatus?.selectedIndex==subIndex && showSelected?'selected':'', tdCenter ? 'td-center' : '']" 
                  v-for="(subCol,cindex) in subcols" :key="cindex">
                    <slot v-if="subCol.slots" name="bodycell" :column="subCol" :record="subRow" :parentRecord="row" :rowCount="row.subRows.length" :rowIndex="subIndex" 
                    :slots="subCol.slots" :extend="subRow.extendColumn"></slot>       
                    <div :style="subcacColumnStyle(subCol,false,false,cindex)" v-else-if="props.searchColumn && props.searchColumn.indexOf(subCol.dataIndex)>=0">
                    <template v-if="subRow.extendColumn">
                      <span v-for="(subcrow,cindex) in subRow.extendColumn[subCol.dataIndex]" :key="cindex">
                        <span class="hightlight" v-if="props.searchText && subcrow===props.searchText.value">{{ subcrow }}</span>
                        <span v-else>{{subcrow}}</span>
                      </span>
                    </template>
                    <div v-else>
                      {{subRow[subCol.dataIndex]}}
                    </div>
                    </div>          
                    <div v-else-if="subCol.dataIndex" :style="subcacColumnStyle(subCol,false,true,cindex)" class="ellipsis">{{ subRow[subCol.dataIndex] }}</div>
                  </td>
                </tr>
              </template>
            </template>
            </table>
          </td>
        </tr>
      </template>
    </template>
    <template v-else>
      <tr>
        <td :colspan="cols.length" class="no-data">
        <div>
          <img src="@/assets/images/nodata.png" alt="">
          <div>沒有数据</div>
        </div>
        </td>
      </tr>
    </template>
    </table>
  </div>
</template>
<script lang="ts">
import { defineComponent,PropType,ref,watch ,Ref, computed} from "vue";
import {DataColumns, DataItem,Columns,SubTableOption} from '@/Interfaces/user/DataItem';
import SubTable from './SubTable.vue'
interface TableDataItem extends DataItem {
  extendColumn?: { [key: string]: string[]};
  name:string,
  selectedIndex?:number,
  subrowStatus?:{selectedIndex:number,overIndex:number}    
}
export default defineComponent({
  name:"FugleTable",
  components:{
    SubTable
  },
  props:{  
    tableTitle:{
      type:String,
    },
    data: {
      type: Object as PropType<any[]>,
      required: true
    },
    height:{
      type:String,

    },
    showSubItems:{
      type:Boolean,
      default:false
    },
    columns: {
      type: Object as PropType<DataColumns[]>,
      required: true
    },
    subColumns: {
      type: Object as PropType<DataColumns[]>,
    },
    styles:{
      type:Object,
      default:()=>{return {}}      
    },
    searchColumn:{
      type:Object as PropType<string[]>,
        required: true
    },
    windowWidth:{
      type:Number,
      default:1300
    },
    contentWidth:{
      type:Number,
      default:1800
    },
    searchText:{
      type:Object as PropType<Ref<string>>,
        required: true
    },
    headerStyle:{
      type:Object,
      default:()=>{return {'background-color':'#eef5f9'}}     
    },
    cellCenter:{
      type:Boolean,
      default:false
    },
    enableRowHover:{
      type:Boolean,
      default:true
    },
    pageSize:{
      type:Number,
      default:400
    },
    subTable:{
      type:Object as PropType<SubTableOption>
    },
    showSelected:{
      type:Boolean,
      default:true
    }
  },
  setup(props, { emit }) {
    
    const dSource=ref<any[]>(props.data);
      dSource.value.forEach((itm:any)=>{
        if(!itm.extendColumn)
          itm.extendColumn={}
        if(props.searchColumn){
          for(let col of props.searchColumn){  
            if(props.searchText){
              itm.extendColumn[col]= [itm[col]]
            }
          }
        }
      })
      const subRowClick =(row)=>{
        console.log(row)
        emit('subRowClick',row)
      }
      const expandClick=(row)=>{
        if(row.expand && row.expand==true){
          row.expand=false
        }else{
          row.expand=true
        }
      }
      const dataSource = computed(() => {  
        const searchText = props.searchText?.value;  
        const searchColumns = props.searchColumn;  
        
        if (!searchText || searchColumns.length === 0) {
          if(dSource.value){  
            return dSource.value.slice(0,props.pageSize)
          }else{
            return []
          }
        }  
        
        let rrs= dSource.value.filter(itm => {  
          return searchColumns.some(column => {  
            const itemValue = itm[column];  
            return itemValue && itemValue.indexOf(searchText) >= 0;  
          });  
        });  
        return rrs.slice(0,props.pageSize)
      });
    watch(()=>props.data,()=>{
      dSource.value=props.data;
    })
    watch(() => props.searchText, () => {
      if(props.searchText){        
      dataSource.value.forEach((itm:TableDataItem)=>{
          if(!itm.extendColumn)
            itm.extendColumn={}
          if(props.searchColumn){
            for(let col of props.searchColumn){  
              if(props.searchText && itm[col]){
                itm.extendColumn[col]= itm[col].toString().split(new RegExp(`(?<=${props.searchText.value})|(?=${props.searchText.value})`, 'i'))
                //console.log(itm.extendColumn)
              }
            }
          }
        }) 
      }
    })
    const tdCenter=computed(()=>props.cellCenter)
    const enableHover=computed(()=>props.enableRowHover)
    const cols=ref<Columns[]>([]);
    const subcols=ref<Columns[]>([]);
    for(let c of props.columns){
      cols.value.push({...c,fixedWidth:0})
    }
    if(props.subColumns){
      for(let c of props.subColumns){
        subcols.value.push({...c,fixedWidth:0})
      }
    }
    let tempWidth=0;
    let totalWidth=0;
    for(let i=0;i<cols.value.length;i++){
      if(cols.value[i].fixed=='left'){        
          tempWidth=cols.value[i].width as number      
          cols.value[i].fixedWidth=totalWidth
          totalWidth+=tempWidth;
      }
    }
    tempWidth=0;
    totalWidth=0;
    for(let i=0;i<subcols.value.length;i++){
      if(subcols.value[i].fixed=='left'){        
          tempWidth=subcols.value[i].width as number      
          subcols.value[i].fixedWidth=totalWidth
          totalWidth+=tempWidth;
      }
    }
    const tableWidth=ref(props.contentWidth+'px')
    const winWidth=ref(props.windowWidth+'px')
    const mainTable=ref();
    let showLeftShadow=ref(false);
    let showRightShadow=ref(true);
    const rowStatus=ref({
      overIndex:-1,
      selectedIndex:-1
    });
    const subRowStatus=ref({
      overIndex:-1,
      selectedIndex:-1
    });
    const handleMouseOver=(index:number)=>{
      if(enableHover.value){
      //console.log(enableHover.value)
      rowStatus.value.overIndex=index;
      }
    }
    const mouselevel =() =>{
      rowStatus.value.overIndex=-1
    }
    const selectItem=(item:TableDataItem,index:number)=>{
      rowStatus.value.selectedIndex=index
      emit("rowcick",item)
    }

    const subhandleMouseOver=(row:TableDataItem, index:number)=>{
      if(enableHover.value){
      //console.log(enableHover.value)
      subRowStatus.value.overIndex=index;
      if(!row.subrowStatus){
        row.subrowStatus={selectedIndex:-1,overIndex:index}
      }
      row.subrowStatus.overIndex=index
      }
    }
    const submouselevel =(item:TableDataItem) =>{
      subRowStatus.value.overIndex=-1
      if(!item.subrowStatus){
        item.subrowStatus={selectedIndex:-1,overIndex:-1}
      }
      item.subrowStatus.overIndex=-1
    }
    const subselectItem=(row,item:TableDataItem,index:number)=>{
      if(!row.subrowStatus){
        row.subrowStatus={selectedIndex:index,overIndex:-1}
      }
      row.subrowStatus.selectedIndex=index
      emit("rowcick",item)
    }
    const getClassNames=(col)=>{ 
      //console.log(col)    
      let classNames:string[]=[];
      if(showLeftShadow.value && col.fixed=='left') classNames.push('shadow-left');
      if(showRightShadow.value && col.fixed=='right') classNames.push('shadow-right');
      if(col.fixed && col.fixed=='right') classNames.push('col-fixed-right');
      if(col.fixed && col.fixed=='left') classNames.push('col-fixed');
      return classNames;
    }
    const scrollEvent=(e)=>{         
        showRightShadow.value=!(e.target.scrollLeft + e.target.clientWidth >= e.target.scrollWidth-2)
        showLeftShadow.value=e.target.scrollLeft>0
    }
    const cacColumnStyle=(col,isHeader,isdiv,index)=>{
      let style = {}
      if(col.width){
        style['width'] = col.width+'px'
      }
      if(index==0 && props.showSubItems){
        style['padding-left']='0'
      }
      if(col.fixed=='left' && !isdiv){
        style['left'] = col.fixedWidth+'px'
        if(isHeader){
          style['z-index']=4;
        //style['box-shadow']='0px 0px 5px #ccc'
        }
      }
      if(isHeader){
        if(props.headerStyle){   
          Object.keys(props.headerStyle).forEach((key)=>{
            style[key]=props.headerStyle[key]
          })          
        }
      }
      
      return style
    }
    const subcacColumnStyle=(col,isHeader,isdiv,index)=>{
      let style = {}
      if(col.width){
        style['width'] = col.width+'px'
      }
      if(index==0){
        style['padding-left']='10px'
      }
      if(col.fixed=='left' && !isdiv){
        style['left'] = col.fixedWidth+'px'
        if(isHeader){
          style['z-index']=4;
        //style['box-shadow']='0px 0px 5px #ccc'
        }
      }
      if(isHeader){
        if(props.headerStyle){   
          Object.keys(props.headerStyle).forEach((key)=>{
            style[key]=props.headerStyle[key]
          })          
        }
      }
      
      return style
    }
    const baseStyle=ref({
      ...props.styles,
      height:props.height?props.height:'unset'
    })
    watch(  
      () => props.height,  
      (newValue, oldValue) => {  
        if (newValue) {  
          baseStyle.value.height = newValue;  
        }  
      }  
    ); 
    return {
      emit,props,cacColumnStyle,ref,subRowStatus,baseStyle,subRowClick,scrollEvent,mouselevel,tdCenter,cols,
      subhandleMouseOver,submouselevel,subselectItem,subcacColumnStyle,expandClick,subcols,
      rowStatus,mainTable,selectItem,handleMouseOver,showLeftShadow,tableWidth,winWidth,showRightShadow,getClassNames,dataSource
    }
  }
})
</script>
<style lang="scss" scoped>
@import '~@/assets/styles/variables.module.scss';
.table-container{
  // width:v-bind(winWidth);
  width:calc(100vw - var(--sideBarWidth) - 10px);
  overflow: auto;
  border-left:solid 1px #eaeaea;
  border-bottom:solid 1px #eaeaea;
  
}
.main-table{
    cursor:normal;
    min-width:v-bind(tableWidth);
    width:100%;
    table-layout:auto;
    border-collapse: separate;
    border-spacing:0;
    .no-data{
      text-align: center;
      color:#999999;
      font-size:14px;
      padding:0 20px;
      img{
        width:50px;
      }
    }
    th{
      // background:#eef5f9;

      color:#1a5e62;
      height:35px;
      line-height: 35px;
      padding-left:10px;
      text-align: left;
      font-weight: normal;
      border-top:solid 1px #eaeaea;
      border-bottom:solid 1px rgba(0,0,0,0.2);
      position: sticky;
      top:0;
      text-wrap: nowrap;
    }
    tr{
      height:30px;
      background:#eaeaea;
      line-height: 39px;
    }
    td{
      color:#666666;
      // border-left:solid 1px red;
      // user-select: none;
      height:35px;
      font-size:14px;
      line-height: 35px;
      // text-align: center;
      transition: background 0.3s;
      background:white;
      border-bottom:solid 1px #eaeaea;
      div{
        padding:0 10px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
    .th-background{
      background:rgb(242,255,242)
    }
    .td-center{
      padding-left:0 !important;
      text-align: center;
    }
    .col-fixed{
      left:0px;
      position:sticky;
      z-index:2;
      
    }
    .col-fixed-right{
      right:0px;
      position:sticky;
      z-index:2;
      
    }
    .row-fixed{
      top:0px;
      position:sticky;
      z-index:3;
    }
    .mover{
      background:#e5f2f2;
    }
    .selected{
       background:#d9e1f4;
    }
  }
.shadow-left {
  box-shadow: 3px 0 3px rgba(0, 0, 0, 0.1);
}
.shadow-right {
  box-shadow: -3px 0 3px rgba(0, 0, 0, 0.1);
}
.hightlight{
  background:yellow;
  color:red;
}
.ellipsis {  
  white-space: nowrap; /* 防止文本换行 */  
  overflow: hidden; /* 隐藏超出容器的内容 */  
  text-overflow: ellipsis; /* 用省略号表示溢出的文本 */   
}
</style>