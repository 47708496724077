import { resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4b309132"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  id: "maincontainer",
  class: "main-container"
}
const _hoisted_2 = { class: "fixed-header" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Sidebar = _resolveComponent("Sidebar")!
  const _component_Navbar = _resolveComponent("Navbar")!
  const _component_app_main = _resolveComponent("app-main")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["app-wrapper", _ctx.store.getters.sideBarOpened ? 'menu-open' : 'hideSidebar'])
  }, [
    _createVNode(_component_Sidebar, {
      class: "sidebar-container",
      style: _normalizeStyle({ background: _ctx.variables.menuBg })
    }, null, 8, ["style"]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_Navbar)
      ]),
      _createVNode(_component_app_main)
    ])
  ], 2))
}