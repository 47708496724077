<template>
  <div class="app-wrapper" :class="store.getters.sideBarOpened ? 'menu-open' : 'hideSidebar'">
    <Sidebar class="sidebar-container" :style="{ background: variables.menuBg }"></Sidebar>
    <div id="maincontainer" class="main-container">
      <div class="fixed-header">
        <Navbar></Navbar>
      </div>
      <app-main></app-main>
    </div>
  </div>
</template>
<script lang="ts">
import Navbar from './components/Navbar.vue'
import AppMain from './components/AppMain.vue'
import Sidebar from './components/sidebar/index.vue'
// import { watch } from 'vue'
import variables from '@/assets/styles/variables.module.scss'
import store from '@/store'
import { defineComponent, onMounted, ref, watch, watchEffect } from 'vue'
// watch(store.getters.sideBarOpened,()=>{
//   variables.$sideBarWidth='80px'
//     })
export default defineComponent({
  components: {
    Navbar, AppMain, Sidebar
  },
  setup() {
    onMounted(()=>{
      window.document.title=store.getters.userInfo.orgName+'管理端'
      store.dispatch('user/getSettings')
    })
    return {
      variables, store
    }
  }
})
</script>
<style lang="scss" scoped>
@import '~@/assets/styles/mixin.scss';
@import '~@/assets/styles/variables.module.scss';


.app-wrapper {
  @include clearfix;
  position: relative;
  min-height: 100vh;
  height: 100%;
  width: 100%;
  display: flex;
  overflow: hidden;
}

.fixed-header {
  z-index: 9;
  width: calc(100vw - #{ $sideBarWidth });
  height: $fixedHeaderHeight;
  // position: fixed;
  // top: 0;
  transition: width #{$sideBarDuration};
  box-shadow: 0px 7px 14px 0px rgba(0, 0, 0, 0.05);
  background-color: #fff;
}
</style>
